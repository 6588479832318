import { HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import { H2 } from '@/components/typography/Headings';
import { Brand } from '@/components/types';
import { Breadcrumbs } from '@/components/breadcrumbs';
import IconExternalLink from '@/components/icons/IconExternalLink';
import { useHistory } from 'react-router-dom';

type BrandHeaderProps = {
  brand: Brand;
};

export default function BrandHeader({ brand }: BrandHeaderProps) {
  const history = useHistory();
  const ICON_SIZE = '64px';

  return (
    <VStack
      align="flex-start"
      padding={{ base: '16px 16px 16px 16px', md: '24px 32px 36px 32px' }}
      bg={`linear-gradient(180deg, rgba(0, 0, 0, 0.00) 37%, rgba(0, 0, 0, 0.43) 100%), url(${brand.bannerImageUrl})`}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgBlendMode="multiply, normal"
    >
      <Breadcrumbs
        crumbs={['Merch Shop', brand.name]}
        handlers={[() => history.push('/products'), () => {}]}
        primaryColor="white"
        secondaryColor="white"
        chevronColor="white"
      />

      <VStack align="flex-start" mt={{ base: '137px', md: '41px' }}>
        <Image
          src={brand.iconUrl}
          alt={brand.name}
          width={ICON_SIZE}
          height={ICON_SIZE}
          mb="20px"
        />
        <H2 color="white" mb={{ base: '0px', md: '20px' }}>
          {brand.name}
        </H2>
        <Text color="white" textStyle="body-md" mb={{ base: '22px', md: '20px' }}>
          {brand.description}
        </Text>
        <Link href={brand.websiteUrl} isExternal>
          <HStack gap="8px">
            <Text color="white" textStyle="body-md">
              Visit Website
            </Text>
            <IconExternalLink color="white" />
          </HStack>
        </Link>
      </VStack>
    </VStack>
  );
}
