import { useHistory } from 'react-router-dom';

import GuestPageContainer from '@/layouts/GuestPageContainer';
import ProductsPage from './ProductsPage';

export default function ProductsPageGuest() {
  const history = useHistory();

  const handleSignedIn = () => {
    history.push('/products');
  };

  return (
    <GuestPageContainer onSignedIn={handleSignedIn} pageName="Merch Shop">
      <ProductsPage />
    </GuestPageContainer>
  );
}
