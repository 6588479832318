import { Template } from '@/lib';
import { Image, Text, VStack } from '@chakra-ui/react';

const ARTBOARD_IMAGE_WIDTH = 103;
const ARTBOARD_IMAGE_DESKTOP_WIDTH = 130;

const ARTBOARD_THUMBNAIL_WIDTH = 130;
const ARTBOARD_THUMBNAIL_HEIGHT = 145;

const ARTBOARD_THUMBNAIL_DESKTOP_WIDTH = 185;
const ARTBOARD_THUMBNAIL_DESKTOP_HEIGHT = 200;

interface TemplateThumbnailProps {
  isSelected?: boolean;
  onClick: () => void;
  template: Template;
}

const TemplateThumbnail = ({ isSelected, onClick, template }: TemplateThumbnailProps) => {
  const { artboardIconUrl, name } = template;

  return (
    <VStack
      cursor="pointer"
      borderRadius="6px"
      border={isSelected ? '1px solid' : ''}
      borderColor="secondaryDarkGray.600"
      bg="lightGray.400"
      gap={{ base: 0, md: '8px' }}
      onClick={onClick}
      pt={{ base: '4px', md: '16px' }}
      h={{
        base: ARTBOARD_THUMBNAIL_HEIGHT,
        md: ARTBOARD_THUMBNAIL_DESKTOP_HEIGHT,
      }}
      w={{
        base: ARTBOARD_THUMBNAIL_WIDTH,
        md: ARTBOARD_THUMBNAIL_DESKTOP_WIDTH,
      }}
    >
      <Image
        w={{
          base: ARTBOARD_IMAGE_WIDTH,
          md: ARTBOARD_IMAGE_DESKTOP_WIDTH,
        }}
        objectFit="contain"
        src={artboardIconUrl}
      />
      <Text
        textAlign="center"
        textStyle={{ base: 'bodySuperSmall', md: 'bodySmall' }}
        fontWeight={600}
      >
        {name}
      </Text>
    </VStack>
  );
};

export default TemplateThumbnail;
