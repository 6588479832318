import { getCanvas, getDesignOutputImageFromJson, getPreviewImage } from './canvas-export';

export const getDefaultDesignSidePreviewImage = (design, side) => {
  const { template, templateColorId } = design;

  const templateSide = side?.templateSide || template.sides[0];

  const images = template.colors.find(({ id }) => id === templateColorId).images;

  const templateImage = images.find(({ templateSideId }) => templateSide.id === templateSideId);

  return templateImage.url;
};

export const getDesignSideWithPreviewImage = (design, side) => {
  const { isPublished, template, templateColorId } = design;

  const { canvasState, manufacturingImageUrl, templateSide } = side || {};

  if (isPublished && manufacturingImageUrl) {
    return getPreviewImage(manufacturingImageUrl, template, templateSide, templateColorId).then(
      (previewImage) => ({
        ...side,
        designImage: manufacturingImageUrl,
        previewImage,
      })
    );
  }

  if (!canvasState) {
    return Promise.resolve({
      ...side,
      previewImage: getDefaultDesignSidePreviewImage(design, side),
    });
  }

  const canvas = getCanvas(templateSide, template);

  return getDesignOutputImageFromJson(canvas, canvasState).then((designOutputImage) => {
    return getPreviewImage(designOutputImage, template, templateSide, templateColorId).then(
      (previewImage) => ({
        ...side,
        designImage: designOutputImage,
        previewImage,
      })
    );
  });
};

export const getDesignWithPreviewImage = (design) =>
  getDesignPreviewImage(design).then(({ designImage, previewImage }) => ({
    ...design,
    designImage,
    previewImage,
  }));

export const getDesignPreviewImage = (design) => {
  const { isPublished, sides = [], template, templateColorId } = design;

  if (isPublished && sides[0]?.manufacturingImageUrl) {
    return getPreviewImage(
      sides[0]?.manufacturingImageUrl,
      template,
      sides[0].templateSide,
      templateColorId
    ).then((previewImage) => ({
      designImage: sides[0]?.manufacturingImageUrl,
      previewImage,
    }));
  }

  return getDesignSideWithCanvasState(sides[0]).then((side) =>
    getDesignSideWithPreviewImage(design, side).then(({ designImage, previewImage }) => ({
      designImage,
      previewImage,
    }))
  );
};

export const getDesignSideWithCanvasState = (side) => {
  const { canvasStateUrl } = side || {};

  if (!canvasStateUrl) {
    return Promise.resolve(side);
  }

  return fetch(canvasStateUrl)
    .then((response) => response.text())
    .then((canvasState) => ({ ...side, canvasState }))
    .catch(() => side);
};
