import IconCloseModal from '@/components/icons/IconCloseModal';

import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Show,
  useBreakpointValue,
} from '@chakra-ui/react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import './ProductDetailsZoomPage.css';
import ScrollIndicator from './ScrollIndicator';
import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import useClickAndPan from '@/constants/useClickAndPan';

const CLOSE_BUTTON_SIZE = 24;

const CLOSE_BUTTON_OFFSET = 12;

interface ProductDetailsZoomPageProps {
  images: { id?: string; url?: string }[];
  initialImageIndex?: number;
  onClose: () => void;
}

const ProductDetailsZoomPage = ({
  images: allImages,
  initialImageIndex,
  onClose,
}: ProductDetailsZoomPageProps) => {
  const imagesRef = useRef([]);

  const [activeImageIndex, setActiveImageIndex] = useState(initialImageIndex);

  const [images, setImages] = useState([]);

  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const loadedImages = [...allImages];

    setImages(loadedImages);
  }, [allImages]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveImageIndex(parseInt(entry.target.id, 10));
        }
      });
    });

    imagesRef.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    if (!isEmpty(imagesRef.current)) {
      imagesRef.current[initialImageIndex].scrollIntoView();
    }

    return () => {
      imagesRef.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, [images, initialImageIndex]);

  const { handleMouseDown, handleMouseUp, handleMouseMove } = useClickAndPan(onClose);

  return (
    <Modal isOpen={true} onClose={onClose} motionPreset="slideInBottom" scrollBehavior="inside">
      <ModalContent
        alignSelf="center"
        p={0}
        borderRadius={0}
        h={{ base: '100%', md: '100dvh' }}
        maxH="100dvh"
        overflow="hidden"
        cursor="zoom-out"
        w="100vw"
        maxW="none"
      >
        <ModalCloseButton
          bg="transparent"
          top={`${CLOSE_BUTTON_OFFSET}px`}
          h={`${CLOSE_BUTTON_SIZE}px`}
          w={`${CLOSE_BUTTON_SIZE}px`}
          right={`${CLOSE_BUTTON_OFFSET}px`}
          zIndex={2}
          _focusVisible={{
            boxShadow: 'none',
          }}
          _focus={{
            bg: 'none',
          }}
        >
          <IconCloseModal backgroundColor="transparent" color="#626F82" />
        </ModalCloseButton>
        <ModalBody p={0}>
          <Show above="md">
            <Box position="absolute" left="16px" top="40%">
              <ScrollIndicator activeIndex={activeImageIndex} numberOfItems={images.length} />
            </Box>
          </Show>
          <TransformWrapper
            panning={{ disabled: isMobile }}
            initialScale={isMobile ? 1.5 : 2}
            initialPositionX={100}
            initialPositionY={100}
            centerOnInit
            pinch={{
              disabled: !isMobile,
            }}
            wheel={{
              disabled: true,
            }}
          >
            <TransformComponent>
              {images.map((image, index) => (
                <Image
                  objectFit={isMobile ? 'cover' : 'contain'}
                  id={`${index}`}
                  ref={(element) => (imagesRef.current[index] = element)}
                  key={index}
                  onMouseDown={handleMouseDown}
                  onMouseUp={() => handleMouseUp(index)}
                  onMouseMove={handleMouseMove}
                  src={image.url}
                  mb="10px"
                  h="100%"
                  w="100%"
                />
              ))}
            </TransformComponent>
          </TransformWrapper>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProductDetailsZoomPage;
