import Badge from '@/components/badge';
import IconFilter from '@/components/icons/IconFilter';
import { Box, IconButton } from '@chakra-ui/react';

interface ButtonToggleFiltersProps {
  isVisible: boolean;
  onToggleVisible: (isVisible: boolean) => void;
  count: number;
}

const ButtonToggleFilters = ({ isVisible, onToggleVisible, count }: ButtonToggleFiltersProps) => (
  <Box position="relative">
    <IconButton
      borderColor={isVisible ? 'black.500' : 'borderLight'}
      variant="secondary"
      aria-label="Filter"
      icon={<IconFilter />}
      onClick={() => onToggleVisible(!isVisible)}
      _focus={{
        borderColor: isVisible ? 'black.500' : 'secondaryGray.400',
        bg: 'transparent',
      }}
      _hover={{
        borderColor: 'secondaryGray.400',
        bg: 'transparent',
      }}
    ></IconButton>
    {count ? <Badge itemsCount={count} right="-8px" top="-8px" /> : null}
  </Box>
);

export default ButtonToggleFilters;
