import { useEffect, useState } from 'react';

import { Template } from '@/lib/types';
import ImagePreviewGallery from '@/components/images/ImagePreviewGallery';
import { findIndex } from 'lodash';

interface TemplatePreviewGalleryProps {
  template: Template;
  onZoom?: () => void;
  onSelectedIndex?: (index: number) => void;
}

const TemplatePreviewGallery = ({
  template,
  onZoom,
  onSelectedIndex,
}: TemplatePreviewGalleryProps) => {
  const [selectedImageId, setSelectedImageId] = useState<string>(null);

  useEffect(() => {
    if (!template?.colors) {
      return;
    }

    setSelectedImageId(template.colors[0].images[0].id);
  }, [template.id]);

  const { colors, mockupImages } = template;

  const images = colors
    ? [...colors[0].images.map(({ id, url }) => ({ url, id })), ...(mockupImages || [])]
    : [];

  return (
    <ImagePreviewGallery
      images={images}
      onSelectedImageId={(id) => {
        setSelectedImageId(id);

        if (onSelectedIndex) {
          onSelectedIndex(findIndex(images, (image) => image.id === id));
        }
      }}
      selectedImageId={selectedImageId}
      onZoom={onZoom}
    />
  );
};

export default TemplatePreviewGallery;
