import { ReactNode, useState } from 'react';

import { Box, BoxProps, Button, Text } from '@chakra-ui/react';
import IconUp from './IconUp';
import IconDown from './IconDown';

interface PanelProps extends BoxProps {
  children: ReactNode;
  title: string;
  onToggleExpanded?: (isExpanded: boolean) => void;
  noBorderBottom?: boolean;
}

const Panel = ({ children, noBorderBottom, title, onToggleExpanded, ...rest }: PanelProps) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <Box w={{ base: '100%', md: '382px' }} {...rest}>
      <Button
        borderBottom={noBorderBottom ? '' : '1px solid'}
        borderTop="1px solid"
        borderColor="borderSecondary"
        alignItems="center"
        bg="transparent"
        borderRadius={0}
        color="#0000000"
        display="flex"
        fontWeight={400}
        height="63px"
        justifyContent="space-between"
        onClick={() => {
          if (onToggleExpanded) {
            onToggleExpanded(!isOpen);
          }

          setOpen(!isOpen);
        }}
        padding={{ base: '18px 0', md: '20px 0' }}
        w="100%"
        _hover={{ bg: '' }}
        _active={{
          bg: '',
        }}
        _focus={{
          bg: '',
          boxShadow: '',
        }}
      >
        <Text fontSize={{ base: 'sm', md: 'md' }} mr="8px">
          {title}
        </Text>
        {isOpen ? <IconUp /> : <IconDown />}
      </Button>
      {isOpen ? (
        <Box pb="20px" pt={noBorderBottom ? 0 : '20px'}>
          {children}
        </Box>
      ) : null}
    </Box>
  );
};

export default Panel;
