import { Center, Hide, Show, Stack, useBreakpointValue } from '@chakra-ui/react';

import ImageThumbnail from './ImageThumbnail';

const OVERFLOW_STYLE = {
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  'scrollbar-width': 'none',
};

interface ImagePreviewGalleryProps {
  images: { url?: string; id?: string }[];
  onZoom?: () => void;
  onSelectedImageId?: (id: string) => void;
  selectedImageId: string;
}

const ImagePreviewGallery = ({
  images,
  onZoom,
  onSelectedImageId,
  selectedImageId,
}: ImagePreviewGalleryProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const thumnbailImageWidth = isMobile ? 82 : 86;

  const selectedImage = images.find(({ id }) => id === selectedImageId);

  return (
    <Stack
      align="flex-start"
      bg="#FFFFFF"
      borderRadius="10px"
      direction={{ base: 'column', md: 'row' }}
      justify={{ base: 'space-between', md: 'flex-start' }}
      h="100%"
      spacing="5px"
      w={{ base: '100%', md: 'auto' }}
    >
      <Hide above="md">
        {selectedImage ? (
          <ImageThumbnail
            url={selectedImage.url}
            imageWidth={265}
            height="auto"
            onZoom={onZoom}
            width="100%"
          />
        ) : null}
      </Hide>
      {images.length ? (
        <Stack
          direction={{ base: 'row', md: 'column' }}
          spacing={{ base: '5px', md: '8px' }}
          overflow="auto"
          css={OVERFLOW_STYLE}
          h={{ md: '529px' }}
          w={{ base: '100%', md: '88px' }}
        >
          {images.map(({ id, url }, index) => {
            const isSelected = id === selectedImageId;

            return (
              <Center
                as="button"
                borderRadius={4}
                borderColor="secondaryDarkGray.600"
                borderWidth={isSelected ? '1px' : 0}
                key={id}
                onClick={() => onSelectedImageId(images[index].id)}
              >
                <ImageThumbnail
                  url={url}
                  imageWidth={64}
                  isClickable
                  height={isMobile ? 82 : 86}
                  width={thumnbailImageWidth}
                />
              </Center>
            );
          })}
        </Stack>
      ) : null}
      <Show above="md">
        {selectedImage ? (
          <ImageThumbnail
            url={selectedImage.url}
            imageWidth={520}
            height={529}
            width={540}
            onZoom={onZoom}
          />
        ) : null}
      </Show>
    </Stack>
  );
};

export default ImagePreviewGallery;
