import { Box, HStack, Image, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import { H1 } from '../../../components/typography/Headings';
import CrocsBannerImage from './CrocsBanner.png';
import CrocsBannerImageMobile from './CrocsBannerMobile.png';
import CrocsLogo from './CrocsLogo.png';
import Button from '../../../components/button';
import { DESIGN_STUDIO } from '@/constants';

interface CrocsBannerProps {
  onStartDesigning: () => void;
  onExploreCollection: () => void;
}

export const CrocsBanner = ({ onStartDesigning, onExploreCollection }: CrocsBannerProps) => {
  const BORDER_RADIUS = { base: '0px', md: '16px' };
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <VStack
      w="100%"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      bgImage={isMobile ? CrocsBannerImageMobile : CrocsBannerImage}
      borderRadius={BORDER_RADIUS}
      align="flex-start"
    >
      <Box
        bg={'linear-gradient(90deg, rgba(20, 62, 230, 0.12) 0%, rgba(255, 255, 255, 0) 100%)'}
        h="100%"
        pb={{ base: '32px', md: '40px' }}
        pt={{ base: '27px', md: '40px' }}
        px={{ base: '20px', md: '40px' }}
        borderRadius={BORDER_RADIUS}
        w="100%"
      >
        <VStack align="flex-start" w={{ base: '100%', md: '448px' }}>
          <Image src={CrocsLogo} h="16px" mb={{ base: '307px', md: '67px' }} />
          <H1
            color="#FFFFFF"
            fontSize={{ base: '25px', md: '40px' }}
            lineHeight={{ base: '28px', md: '55px' }}
            mb="12px"
          >
            Design Your Own Jibbitz™
          </H1>
          <Text color="#FFFFFF" mb={{ base: '20px', md: '28px' }}>
            Use AI to customize your Crocs Jibbitz™. Turn your photos into amazing graphics and
            start expressing your unique style. Just a few easy steps and you’ll be on your way.
          </Text>
          <HStack w={{ base: '100%', md: '416px' }}>
            <Button
              flex={{ base: '100%', md: 1 }}
              label="Design"
              onClick={onStartDesigning}
              target={DESIGN_STUDIO}
            >
              Start Designing
            </Button>
            <Button
              flex={{ base: '100%', md: 1 }}
              label="Explore Collection"
              onClick={onExploreCollection}
              secondary
              target="Merch Shop"
            >
              Explore Collection
            </Button>
          </HStack>
        </VStack>
      </Box>
    </VStack>
  );
};
