import axios from 'axios';

import { sortBy } from 'lodash';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  Category,
  Template,
  TemplateColor,
  TemplateColorImage,
  TemplateSide,
} from '@/components/types';
import {
  getTemplateWithPreviewImage,
  getTemplateWithInitialCanvasStatesFromTemplate,
} from '@/lib/utils/template-preview';
import { TemplatePreviewImage } from '@/lib';
import { useToast } from '@chakra-ui/react';

const entity = 'templates';

const URL = `/${entity}`;

export interface IGetTemplateOptions {
  clientId?: string;
  categoryId?: string;
  isFeatured?: boolean;
  isDefault?: boolean;
}

const orderTemplateSidesColorsAndPreviewImages = (template) => {
  const { colors = [], sides } = template;

  const colorsWithOrderedPreviewImages = colors.map((color) => {
    const { previewImages } = color;

    return {
      ...color,
      previewImages: sortBy(previewImages, ({ order }) => order),
    };
  });

  return {
    ...template,
    sides: sortBy(sides, ({ order }) => order),
    colors: sortBy(colorsWithOrderedPreviewImages, ({ order }) => order),
  };
};

export const getTemplatesWithPreviewImages = (params?: IGetTemplateOptions) =>
  getTemplates(params).then((templates) => Promise.all(templates.map(getTemplateWithPreviewImage)));

export const getDefaultTemplates = () =>
  axios
    .get<Template[]>(URL + '?isDefault=true')
    .then(({ data }) => data.map(orderTemplateSidesColorsAndPreviewImages));

export const getTemplates = (params?: IGetTemplateOptions) =>
  axios
    .get<Template[]>(URL, { params })
    .then(({ data }) => data.map(orderTemplateSidesColorsAndPreviewImages));

export const useBasicTemplates = (params?: IGetTemplateOptions) =>
  useQuery([entity, 'basic'], () => getTemplates(params));

export const useTemplates = (params?: IGetTemplateOptions) =>
  useQuery([entity], () => getTemplatesWithPreviewImages(params));

export const getTemplateBasic = (id: string) =>
  axios.get<Template>(`${URL}/${id}`).then(({ data }) => data);

export const getTemplate = (id: string) =>
  getTemplateBasic(id).then((template) =>
    getTemplateWithPreviewImage(orderTemplateSidesColorsAndPreviewImages(template)).then(
      getTemplateWithInitialCanvasStatesFromTemplate
    )
  );

export const useTemplate = (id: string) => useQuery([entity, id], () => getTemplate(id));

export const createTemplate = (template: Template): Promise<Template> => {
  return axios.post<Template>(`${URL}/full`, template).then(({ data }) => data);
};

export const updateTemplate = (template: Template) => {
  return axios.patch<Template>(`${URL}/${template.id}`, template).then(({ data }) => data);
};

export const saveTemplate = (template: Template) => {
  const method = template.id ? updateTemplate : createTemplate;

  return method(template);
};

export const deleteTemplate = (id) => axios.delete(`${URL}/${id}`);

export const useDeleteTemplate = () => {
  const client = useQueryClient();

  const toast = useToast();

  const { isLoading, mutate: removeTemplate } = useMutation((id: string) => deleteTemplate(id), {
    onSuccess: () => {
      client.invalidateQueries([entity]);

      toast({
        title: 'Template successfully deleted',
        status: 'success',
      });
    },
  });

  return {
    isDeleting: isLoading,
    removeTemplate,
  };
};

export const createTemplateSide = (templateId: string, side: TemplateSide): Promise<TemplateSide> =>
  axios.post<TemplateSide>(`${URL}/${templateId}/sides`, side).then(({ data }) => data);

export const updateTemplateSide = (
  templateId: string,
  updates: Partial<TemplateSide>
): Promise<TemplateSide> =>
  axios
    .patch<TemplateSide>(`${URL}/${templateId}/sides/${updates.id}`, updates)
    .then(({ data }) => data);

export const deleteTemplateSide = (templateId, sideId) =>
  axios.delete(`${URL}/${templateId}/sides/${sideId}`);

export const createTemplateColor = (
  templateId: string,
  color: TemplateColor
): Promise<TemplateColor> =>
  axios.post<TemplateColor>(`${URL}/${templateId}/colors`, color).then(({ data }) => data);

export const updateTemplateColor = (
  templateId: string,
  color: TemplateColor
): Promise<TemplateColor> =>
  axios
    .patch<TemplateColor>(`${URL}/${templateId}/colors/${color.id}`, color)
    .then(({ data }) => data);

export const deleteTemplateColor = (templateId, colorId) =>
  axios.delete(`${URL}/${templateId}/colors/${colorId}`);

export const createTemplateColorImage = (templateId, image) =>
  axios.post<TemplateColorImage>(`${URL}/${templateId}/images`, image).then(({ data }) => data);

export const updateTemplateColorImage = (templateId, imageId, image) =>
  axios
    .patch<TemplateColorImage>(`${URL}/${templateId}/images/${imageId}`, { image })
    .then(({ data }) => data);

export const deleteTemplateColorPreviewImage = (
  templateId: string,
  templateColorId: string,
  imageId: string
) => axios.delete(`${URL}/${templateId}/colors/${templateColorId}/preview-images/${imageId}`);

export const createTemplateColorPreviewImage = (
  templateId: string,
  templateColorId: string,
  previewImage: TemplatePreviewImage
) =>
  axios
    .post<TemplatePreviewImage>(
      `${URL}/${templateId}/colors/${templateColorId}/preview-images`,
      previewImage
    )
    .then(({ data }) => data);

export const updateTemplateColorPreviewImage = (
  templateId: string,
  templateColorId: string,
  imageId: string,
  updates: Partial<TemplatePreviewImage>
) =>
  axios
    .patch<TemplatePreviewImage>(
      `${URL}/${templateId}/colors/${templateColorId}preview-images/${imageId}`,
      updates
    )
    .then(({ data }) => data);

export const getCategories = (): Promise<Category[]> =>
  axios.get(`${URL}/categories`).then(({ data }) => sortBy(data, 'order'));

export const useCategories = () => useQuery([entity, 'categories'], () => getCategories());

export const getTemplateSizeChart = (templateId: string) =>
  axios.get(`${URL}/${templateId}/size-chart`).then(({ data }) => data);

export const useTemplateSizeChart = (templateId: string) =>
  useQuery([entity, 'size-chart'], () => getTemplateSizeChart(templateId));
