import { Button, useBreakpointValue } from '@chakra-ui/react';
import IconShare from '../icons/IconShare';

type ShareButtonProps = {
  canShare: boolean;
  onCopyLink: () => void;
  onShare: () => void;
};

export default function ShareButton({ canShare, onCopyLink, onShare }: ShareButtonProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const BUTTON_SIZE = '50px';

  return (
    (canShare || !isMobile) && (
      <Button onClick={isMobile ? onShare : onCopyLink} w={BUTTON_SIZE} h={BUTTON_SIZE}>
        <IconShare />
      </Button>
    )
  );
}
