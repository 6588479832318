import { AnalyticsBrowser } from '@segment/analytics-next';
import { Event } from './events';
import Config from '../config';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { capitalize } from 'lodash';

const analytics = AnalyticsBrowser.load({ writeKey: Config.SEGMENT_WRITE_KEY });

const PAGE_NAMES_FOR_PATHS = {
  '/designs/new': 'Template Selection',
  '/designs': 'My Designs',
  '/': 'Homepage',
  '/auth/signup': 'Sign-Up Page',
  '/auth/signin': 'Sign-In Page',
  '/orders': 'Orders',
  '/verify-email': 'Email Verification Page',
  '/products': 'Merch Shop',
  '/template-library': 'Template library',
  '/team': 'Manage Team',
  '/dashboard': 'Analytics',
  '/custom-art-styles': 'Custom Art Style',
  '/subscription': 'Subscription Plan',
};

export const getPageNameForPath = (pathname: string) => {
  if (PAGE_NAMES_FOR_PATHS[pathname]) {
    return PAGE_NAMES_FOR_PATHS[pathname];
  } else if (pathname.startsWith('/designs')) {
    return 'Design Studio';
  } else if (pathname.startsWith('/products')) {
    return 'Product Details Page';
  } else {
    return capitalize(location.pathname.split('/')[1]);
  }
};

export function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    const pageName = getPageNameForPath(location.pathname);

    analytics.page('page_view', pageName || 'Home', {
      page_url: window.location.href,
    });
  }, [location.pathname]);
}

export function handleLogout() {
  trackEvent(Event.LOGGED_OUT);
}

export function identifyUser(user) {
  delete user.password;

  analytics.identify(user.id, user);
}

export const trackEvent = (eventName: string, eventProperties?) => {
  analytics.track(eventName, eventProperties);
};

export const trackFormInteractedEvent = (
  source: string,
  fieldName: string,
  interactionType = 'input'
) =>
  trackEvent('form_field_interacted', {
    source,
    field_name: fieldName,
    interaction_type: interactionType,
  });

export const trackCtaClickedEvent = (text: string, category: string, target: string) => {
  const pageName = getPageNameForPath(window.location.pathname);

  trackEvent('cta_clicked', {
    cta_text: text,
    cta_category: category,
    cta_target: target,
    page_name: pageName,
  });
};
