import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
);

const baseStyle = definePartsStyle({
  tablist: {
    borderBottom: 'none',
    gap: { base: '8px', md: '16px' },
    mb: 0,
    w: 'max-content',
  },
  tab: {
    bg: '#FFFFFF',
    border: '1px solid',
    borderColor: 'borderLight',
    borderRadius: '6px',
    color: 'black.500',
    fontSize: { base: '14px', md: '18px' },
    fontWeight: 500,
    height: { base: '32px', md: '44px' },
    padding: { base: '8px 12px', md: '8px 16px' },
    _hover: {
      color: 'brand.600',
    },
    _selected: {
      bg: 'linear-gradient(0deg, rgba(6, 74, 196, 0.05) 0%, rgba(6, 74, 196, 0.05) 100%), #F0F5FF',
      border: 'none',
      color: 'brand.600',
      fontWeight: 600,
    },
  },
});

export const tabsStyles = defineMultiStyleConfig({
  defaultProps: {
    variant: 'unstyled',
  },
  variants: {
    unstyled: baseStyle,
  },
});
