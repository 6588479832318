import { Button, VStack, Text, HStack, Tooltip, Box, useToast } from '@chakra-ui/react';

import { IconLikeOutlineGray } from '../../../components/icons/IconLikeOutlineGray';
import { IconLikeOutlineBlack } from '../../../components/icons/IconLikeOutlineBlack';
import { IconLikeGray } from '../../../components/icons/IconLikeGray';
import IconHeart from '../../../components/icons/IconHeart';
import { likeDesign } from '../../../api/designs';
import { useEffect, useState, useRef } from 'react';
import { trackEvent } from '../../../analytics';
import { Design } from '../../../lib/types';
import { useCategories } from '../../../api/templates';
import { useLocation } from 'react-router-dom';
import ShareButton from '@/components/share/ShareButton';

type HeaderOptionsProps = {
  design: Design;
  onSignInToLike?: () => void;
  hideLike?: boolean;
};

export default function HeaderOptions({ design, hideLike, onSignInToLike }: HeaderOptionsProps) {
  const [isLiked, setIsLiked] = useState(design?.isLiked);
  const [isLikeButtonHovered, setIsLikeButtonHovered] = useState(false);
  const { data: categories = [] } = useCategories();

  const toast = useToast();

  const { search } = useLocation();

  const handleShare = async () => {
    trackEvent('share_product', {
      item_name: design.name,
      item_category: categories.find(({ id }) => id === design.template.categoryId)?.name,
      share_confirmation: true,
    });

    const blob = await (await fetch(design.sides[0].previewImage)).blob();
    const file = new File([blob], `${design.name}.png`, { type: blob.type });

    try {
      await navigator.share({ ...getShareContent(), files: [file] });
    } catch (error) {
      toast({
        title: 'Error sharing content',
        description: error?.message,
        status: 'error',
      });
    }
  };

  const getShareLink = () => window.location.href;
  const shareLink = getShareLink();

  const getShareContent = () => ({
    title: 'Check out this awesome merch I found on Ablo.ai',
    text: 'You can buy this merch on Ablo.ai #AIDesign #Ablo #AI #FashionAI',
    url: getShareLink(),
  });
  const canShare = navigator.share && navigator.canShare(getShareContent());

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareLink);

      toast({
        title: 'Link copied to clipboard',
        status: 'success',
      });
    } catch (error) {
      toast({
        title: 'Error copying link to clipboard',
        description: error.message,
        status: 'success',
      });
    }
  };

  const handleLike = async () => {
    if (onSignInToLike) {
      onSignInToLike();
      return;
    }

    if (isLiked) return;

    setIsLiked(true);

    try {
      await likeDesign(design.id);
    } catch (e) {
      setIsLiked(false);
      console.error(e);
      toast({
        title: 'Error liking design',
        status: 'error',
      });
    }
  };

  // Use a ref to track if we've handled the URL parameter
  const hasHandledUrlParam = useRef(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const isLikingDesign = queryParams.get('likingDesign');

    if (isLikingDesign && !design.isLiked && !hasHandledUrlParam.current) {
      hasHandledUrlParam.current = true;
      handleLike();
    }
  }, []);

  const BUTTON_SIZE = '50px';

  return (
    <HStack>
      <Tooltip
        label={
          <VStack spacing="2px" align="center">
            <Text>{isLiked ? 'You Liked!' : 'Like it!'}</Text>
            <HStack>
              <IconHeart />
              <Text>{design.numLikes}</Text>
            </HStack>
          </VStack>
        }
        bg="white"
        color="black"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="4px"
        p="8px 12px"
        placement="top"
      >
        {!hideLike ? (
          <Box>
            <Button
              onClick={isLiked ? null : handleLike}
              onMouseEnter={() => setIsLikeButtonHovered(true)}
              onMouseLeave={() => setIsLikeButtonHovered(false)}
              w={BUTTON_SIZE}
              h={BUTTON_SIZE}
            >
              {isLiked ? (
                <IconLikeGray />
              ) : isLikeButtonHovered ? (
                <IconLikeOutlineBlack />
              ) : (
                <IconLikeOutlineGray />
              )}
            </Button>
          </Box>
        ) : null}
      </Tooltip>
      <ShareButton canShare={canShare} onCopyLink={handleCopyLink} onShare={handleShare} />
    </HStack>
  );
}
