import { User } from '@/components/types';
import { Flex, VStack } from '@chakra-ui/react';
import { H2 } from '@/components/typography/Headings';
import ProductList from './components/ProductList';
import FeaturedBrands from './components/FeaturedBrands';
import { useParams } from 'react-router-dom';
import BrandHeader from './components/BrandHeader';
import { isValidUUID } from '@/utils/validation';
import { useBrandById, useBrandBySlug } from '@/api/brands';
import LoadingSpinner from '@/components/ui/LoadingSpinner';

interface ProductsListProps {
  me?: User;
}

const ProductsPage = ({ me }: ProductsListProps) => {
  const { idOrSlug } = useParams<{ idOrSlug: string }>();
  const isUUID = isValidUUID(idOrSlug || '');
  const isBrandPage = !!idOrSlug;

  // Call hooks unconditionally, but only one will be enabled
  const { brand: brandById, isLoading: isLoadingById } = useBrandById(isUUID ? idOrSlug || '' : '');
  const { brand: brandBySlug, isLoading: isLoadingBySlug } = useBrandBySlug(
    !isUUID ? idOrSlug || '' : ''
  );

  // Use the appropriate data based on the identifier type
  const brand = isUUID ? brandById : brandBySlug;
  const isLoading = isUUID ? isLoadingById : isLoadingBySlug;

  // Show loading state while fetching brand data
  if (isBrandPage && isLoading) {
    return (
      <Flex h="100%" justify="center" align="center">
        <LoadingSpinner />
      </Flex>
    );
  }

  return (
    <Flex h="100%" bg="#FFFFFF" direction="column" overflow="auto">
      {isBrandPage ? (
        brand ? (
          <BrandHeader brand={brand} />
        ) : null
      ) : (
        <VStack
          padding={{ base: '16px 0px 16px 16px', md: '24px 32px 8px 32px' }}
          align="flex-start"
        >
          <H2 mb={{ base: '8px', md: '24px' }}>Explore featured communities!</H2>
          <FeaturedBrands />
        </VStack>
      )}

      <ProductList user={me} brandId={brand?.id} />
    </Flex>
  );
};

export default ProductsPage;
