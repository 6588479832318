import axios from 'axios';

import { useQuery } from '@tanstack/react-query';

import { Brand } from '@/components/types';

const ENTITY = `brands`;

const getBrands = () => axios.get<Brand[]>(`/${ENTITY}`).then(({ data }) => data);

export const useBrands = () => {
  const { data: brands, isLoading } = useQuery([ENTITY], () => getBrands());

  return { brands, isLoading };
};

export const getBrandById = (id: string) =>
  axios.get<Brand>(`/${ENTITY}/${id}`).then(({ data }) => data);

export const getBrandBySlug = (slug: string) =>
  axios.get<Brand>(`/${ENTITY}`, { params: { slug } }).then(({ data }) => data);

export const useBrandById = (id: string) => {
  const { data: brand, isLoading } = useQuery([ENTITY, id], () => getBrandById(id), {
    enabled: !!id,
  });
  return { brand, isLoading };
};

export const useBrandBySlug = (slug: string) => {
  const { data: brand, isLoading } = useQuery([ENTITY, `slug:${slug}`], () => getBrandBySlug(slug), {
    enabled: !!slug,
  });
  return { brand, isLoading };
};
