import AppContainer from '@/layouts/AppContainer';
import { useMe } from '@/api/auth';
import ProductsPage from './ProductsPage';

const ProductsPageAuthenticated = () => {
  const { data: me } = useMe();

  return (
    <AppContainer contentContainerProps={{ padding: 0 }}>
      <ProductsPage me={me} />
    </AppContainer>
  );
};

export default ProductsPageAuthenticated;
